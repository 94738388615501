import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import Carousel from "../components/carousel"
// import CategoryBar from "../components/category-bar"


import loadable from '@loadable/component'

const Carousel = loadable(() => import("../components/carousel"))
const CategoryBar = loadable(() => import("../components/category-bar"))



export const query = graphql`
query materials ($id: Int) {
  allJrmProductsMaterials(filter: {MaterialTypeId: {eq: $id}}) {
      nodes {
        Id
        MaterialTypeId
        Name
        AttractivePrice
        Slug
      }
  }

  allJrmProducts {
    edges{
      node{
        Id
        DisplayOnFrontend
        PayOnline
        HidePrices
      }
    }
  }

  allJrmCategories {
    nodes {
      Id
      Name
    }
  }

  allJrmFilteredCategories {
    nodes {
        Id
    }
  } 
}
`



const Material = ({data, pageContext}) => {


  var FilteredCategories = []
  data.allJrmCategories.nodes.forEach((item, index) => {
    var exists = data.allJrmFilteredCategories.nodes.find(filteredItem => filteredItem.Id === item.Id)
    if(exists){
      FilteredCategories.push({
        Id: item.Id,
        Name: item.Name
      })
    }
  })

   

    var products = []

    data.allJrmProductsMaterials.nodes.forEach((item, index) => {
      var exists = products.find(x => x.Id === item.Id)
      if(!exists){
        data.allJrmProducts.edges.forEach((productItem, index) => {
          if(productItem.node.DisplayOnFrontend === true && productItem.node.Id === item.Id){
            products.push({
              Id: item.Id,
              Name: item.Name,
              Description: item.Name,
              Slug: item.Slug,
              AttractivePrice: item.AttractivePrice,
              PayOnline: productItem.node.PayOnline,
              HidePrices: productItem.node.HidePrices
            })
          }
        })
      }
    })
    
    

  // uniqueSlugs = data.allJrmProductsMaterials.nodes.map(item => item.Slug).filter((value, index, self) => self.indexOf(value) === index)


    return(
    <div>
      <Layout headerShow={false}>
        <SEO title={pageContext.name} />

        <CategoryBar data={FilteredCategories}></CategoryBar>


        {/* {products.map((item, index) => {

            return(
                <div>id: {item.id} slug: {item.slug} name: {item.name}</div>
            )
        })} */}

        {/* {data.allJrmProductsMaterials.nodes.map((item, i) => {

            
            return (
                <div key={i}>{item.Name} - {item.Slug}</div>
            )
        })} */}


        


        {/* <div>{"asdf: "+pageContext.name}</div>

        <ul>
        {data.allJrmProducts.nodes.map((item, i) => {

            return (
                <li key={i}>{item.Description} - {item.Id}</li>
            )
        })}
        </ul> */}
  
        
        <div className="padding-top padding-bottom">

        <Carousel title={pageContext.name} data={products}></Carousel>
        </div>
  
  
        
  
      </Layout>
  
  
      
    </div>
  )
    }
  
  export default Material